import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import { committees } from '../components/committe'

function ProctorialBoard() {
  return (
    <div>
      <PageHeader title="Proctorial Board" />
      <PageContent elem = {
        <div className="container mx-auto my-5">
              <h2 className="text-center text-primary text-2xl font-bold mb-5">Proctorial Committee</h2>
              <table className="table-auto w-full border-collapse border border-gray-400">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border border-gray-400 px-4 py-2">Committee Name</th>
                    <th className="border border-gray-400 px-4 py-2">Members</th>
                  </tr>
                </thead>
                <tbody>
                  {committees.filter((c)=>c.name=='Proctorial Committee').map((committee, index) => (
                    <tr key={index} className="hover:bg-gray-100">
                      <td className="border border-gray-400 px-4 py-2 font-semibold">{committee.name}</td>
                      <td className="border border-gray-400 px-4 py-2">
                        <ul className="list-disc pl-5">
                          {committee.members.map((member, idx) => (
                            <li key={idx}>{member}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
      } />
    </div>
  )
}

export default ProctorialBoard
