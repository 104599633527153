import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
 
function VisionPage() {
  return (
    <>
    <PageHeader title="About Us" />
    <PageContent elem={<div id="visionPage"><section className="mb-5" >
    <h3 className="text-xl font-semibold mb-2" >Vision of the College</h3>
    <p  style={{textAlign:'justify'}}>
      Our vision is to create a thriving educational ecosystem where individuals, regardless of their socio-economic background, have the opportunity to access and excel in higher education. We envision a future where our degree college serves as a hub of knowledge, innovation, and inspiration, uplifting entire communities and catalyzing socio-economic growth. By blending academic rigor with practical relevance, cultural sensitivity, and a deep commitment to social responsibility, we aspire to be the driving force behind the holistic development and improved quality of life for the people we serve.
    </p>
  </section>
  
  <section className="mb-5">
    <h3 className="text-xl font-semibold mb-2">Mission of the College</h3>
    <p  style={{textAlign:'justify'}}>
      Our mission is to be a transformative force in the lives of individuals residing in economically disadvantaged and underserved communities. We are committed to providing accessible, high-quality higher education that empowers our students with the knowledge, skills, and values necessary to break the cycle of poverty, enhance their standard of living, and contribute positively to society. Through innovation, community engagement, and a student-centered approach, we aim to be a beacon of hope, fostering holistic development and enabling sustainable change.
    </p>
  </section>
  
  
</div>}  />
    </>
    
  )
}

export default VisionPage