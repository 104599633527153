import React from 'react';
import { committees } from './committe';

const committeesOLD = [
  { name: 'Proctorial Committee', members: [
      'Prof. (Dr.) Jai Prakash - Chief Proctor',
      'Dr. Sachin - Deputy Chief Proctor',
      'Dr. Manish Kannojia - Deputy Chief Proctor',
      'Dr. Shailja Asthana - Deputy Chief Proctor',
      'Mr. Santosh Kumar - Deputy Chief Proctor',
      'Mr. Vikash Kumar Sonkar - Deputy Chief Proctor',
      'Dr. Sanjai Kumar Gupta - Deputy Chief Proctor',
      'Mr. Kishor Nand - Deputy Chief Proctor',
      'Mr. Yogendra Nath Mishra - Deputy Chief Proctor'
  ]},
  { name: 'Sports Committee', members: [
      'Mr. Vikash Kumar Sonkar - Coordinator',
      'Dr. Jai Prakash - Member',
      'Mr. Santosh Kumar - Member',
      'Dr. Manish Kannojia - Member',
      'Mr. Jitendra Kumar Chaurasiya - Member'
  ]},
  { name: 'Examination Committee', members: [
      'Dr. Sachin - Coordinator/ Controller',
      'Dr. Manish Kannojia - Member',
      'Dr. Shailja Asthana - Member',
      'Mr. Brijesh Kumar Pandey - Member'
  ]},
  { name: 'Purchase Committee', members: [
      'Dr. Sachin - Coordinator',
      'Prof. (Dr.) Jai Prakash - Deputy Coordinator',
      'Dr. Manish Kannojia - Member',
      'Mr. Vikash Kumar Sonkar - Member',
      'Mr. Kishor Nand - Member',
      'Mr. Jitendra Kumar Chaurasiya - Member',
      'Mr. Brijesh Kumar Pandey - Member',
      'Mrs. Sarita Yadav - Member'
  ]},
  { name: 'Computer Lab & Smart Class Room', members: [
      'Dr. Manish Kannojia - Coordinator',
      'Mr. Jitendra Kumar Chaurasiya - Member',
      'Mr. Kishor Nand - Member'
  ]},
  { name: 'Student Welfare Committee', members: [
      'Prof. (Dr.) Jai Prakash - Coordinator',
      'Dr. Shailja Asthana - Member',
      'Mr. Santosh Kumar - Member',
      'Mr. Vikash Kumar Sonkar - Member',
      'Dr. Sanjai Kumar Gupta - Member'
  ]},
  { name: 'Library Committee', members: [
      'Dr. Sachin - Coordinator',
      'Dr. Sumit Kumar Gupta - Member',
      'Dr. Manish Kannojia - Member',
      'Dr. Sanjai Kumar Gupta - Member',
      'Mr. Kishor Nand - Member',
      'Mr. Santosh Kumar - Member',
      'Mr. Brijesh Kumar Pandey - Member',
      'Mrs. Sarita Yadav - Member'
  ]},
  { name: 'Cultural Activities Committee', members: [
      'Dr. Shailja Asthana - Coordinator',
      'Mr. Vikash Kumar Sonkar - Member',
      'Dr. Sumit Kumar Gupta - Member',
      'Dr. Sanjai Kumar Gupta - Member',
      'Mr. Kishor Nand - Member'
  ]},
  { name: 'Reading Room Committee', members: [
      'Mr. Kishor Nand - Coordinator',
      'Mr. Yogendra Nath Mishra - Member',
      'Mr. Brijesh Kumar Pandey - Member',
      'Mr. Manoj Kumar Tripathi - Assistant'
  ]},
  { name: 'Program Organising Committee', members: [
      'Dr. Shailja Asthana',
      'Mr. Yogendra Nath Mishra'
  ]},
  { name: 'Women Cell', members: [
      'Dr. Shailja Asthana - Coordinator',
      'Mr. Vikash Kumar Sonkar - Member',
      'Mr. Yogendra Nath Mishra - Member',
      'Mr. Kishor Nand - Member',
      'Dr. Sanjai Kumar Gupta - Member'
  ]},
  { name: 'Anti-Ragging Committee', members: [
      'Mr. Santosh Kumar - Coordinator',
      'Dr. Manish Kannojia - Member',
      'Mr. Vikash Kumar Sonkar - Member',
      'Mr. Yogendra Nath Mishra - Member'
  ]},
  { name: 'IQAC Committee', members: [
      'Prof. (Dr.) Jai Prakash - Coordinator',
      'Dr. Sachin - Member',
      'Dr. Shailja Asthana - Member',
      'Dr. Manish Kannojia - Member',
      'Mr. Santosh Kumar - Member'
  ]}
];

const CommitteesTable = () => {
  return (
    <div className="container mx-auto my-5">
      <h2 className="text-center text-primary text-2xl font-bold mb-5">College Committees</h2>
      <table className="table-auto w-full border-collapse border border-gray-400">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-400 px-4 py-2">Committee Name</th>
            <th className="border border-gray-400 px-4 py-2">Members</th>
          </tr>
        </thead>
        <tbody>
          {committees.map((committee, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="border border-gray-400 px-4 py-2 font-semibold">{committee.name}</td>
              <td className="border border-gray-400 px-4 py-2">
                <ul className="list-disc pl-5">
                  {committee.members.map((member, idx) => (
                    <li key={idx}>{member}</li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CommitteesTable;

