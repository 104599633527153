export const committees = [
    {
        "name": "Proctorial Committee",
        "members": [
            { "image": "jai_prakash.jpg", "name": "Prof. (Dr.) Jai Prakash", "designation": "Chief Proctor" },
            { "image": "sachin.jpg", "name": "Dr. Sachin", "designation": "Deputy Chief Proctor" },
            { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kannojia", "designation": "Deputy Chief Proctor" },
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Deputy Chief Proctor" },
            { "image": "santosh_kumar.jpg", "name": "Mr. Santosh Kumar", "designation": "Deputy Chief Proctor" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Deputy Chief Proctor" },
            { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "designation": "Deputy Chief Proctor" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Deputy Chief Proctor" },
            { "image": "yogendra_nath_mishra.jpg", "name": "Mr. Yogendra Nath Mishra", "designation": "Deputy Chief Proctor" }
        ]
    },
    {
        "name": "Sports Committee",
        "members": [
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Coordinator" },
            { "image": "jai_prakash.jpg", "name": "Dr. Jai Prakash", "designation": "Member" },
            { "image": "santosh_kumar.jpg", "name": "Mr. Santosh Kumar", "designation": "Member" },
            { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kannojia", "designation": "Member" },
            { "image": "jitendra_kumar_chaurasiya.jpg", "name": "Mr. Jitendra Kumar Chaurasiya", "designation": "Member" }
        ]
    },
    {
        "name": "Examination Committee",
        "members": [
            { "image": "sachin.jpg", "name": "Dr. Sachin", "designation": "Coordinator/Controller" },
            { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kannojia", "designation": "Member" },
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Member" },
            { "image": "brijesh_kumar_pandey.jpg", "name": "Mr. Brijesh Kumar Pandey", "designation": "Member" }
        ]
    },
    {
        "name": "Purchase Committee",
        "members": [
            { "image": "sachin.jpg", "name": "Dr. Sachin", "designation": "Coordinator" },
            { "image": "jai_prakash.jpg", "name": "Prof.(Dr.) Jai Prakash", "designation": "Deputy Coordinator" },
            { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kannojia", "designation": "Member" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Member" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Member" },
            { "image": "jitendra_kumar_chaurasiya.jpg", "name": "Mr. Jitendra Kumar Chaurasiya", "designation": "Member" },
            { "image": "brijesh_kumar_pandey.jpg", "name": "Mr. Brijesh Kumar Pandey", "designation": "Member" },
            { "image": "sarita_yadav.jpg", "name": "Mrs. Sarita Yadav", "designation": "Member" }
        ]
    },
    {
        "name": "Computer Lab & Smart Class Room",
        "members": [
            { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kannojia", "designation": "Coordinator" },
            { "image": "jitendra_kumar_chaurasiya.jpg", "name": "Mr. Jitendra Kumar Chaurasiya", "designation": "Member" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Member" }
        ]
    },
    {
        "name": "Student Welfare Committee",
        "members": [
            { "image": "jai_prakash.jpg", "name": "Prof.(Dr.) Jai Prakash", "designation": "Coordinator" },
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Member" },
            { "image": "santosh_kumar.jpg", "name": "Mr. Santosh Kumar", "designation": "Member" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Member" },
            { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "designation": "Member" }
        ]
    },
    {
        "name": "Library Committee",
        "members": [
            { "image": "sachin.jpg", "name": "Dr. Sachin", "designation": "Coordinator" },
            { "image": "sumit_kumar_gupta.jpg", "name": "Dr. Sumit Kumar Gupta", "designation": "Member" },
            { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kannojia", "designation": "Member" },
            { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "designation": "Member" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Member" },
            { "image": "santosh_kumar.jpg", "name": "Mr. Santosh Kumar", "designation": "Member" },
            { "image": "brijesh_kumar_pandey.jpg", "name": "Mr. Brijesh Kumar Pandey", "designation": "Member" },
            { "image": "sarita_yadav.jpg", "name": "Mrs. Sarita Yadav", "designation": "Member" }
        ]
    },
    {
        "name": "Cultural Activities Committee",
        "members": [
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Coordinator" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Member" },
            { "image": "sumit_kumar_gupta.jpg", "name": "Dr. Sumit Kumar Gupta", "designation": "Member" },
            { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "designation": "Member" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Member" }
        ]
    },
    {
        "name": "Reading Room Committee",
        "members": [
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Coordinator" },
            { "image": "yogendra_nath_mishra.jpg", "name": "Mr. Yogendra Nath Mishra", "designation": "Member" },
            { "image": "brijesh_kumar_pandey.jpg", "name": "Mr. Brijesh Kumar Pandey", "designation": "Member" },
            { "image": "manoj_kumar_tripathi.jpg", "name": "Mr. Manoj Kumar Tripathi", "designation": "Assistant" }
        ]
    },
    {
        "name": "Program Organising Committee",
        "members": [
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Member" },
            { "image": "yogendra_nath_mishra.jpg", "name": "Mr. Yogendra Nath Mishra", "designation": "Member" }
        ]
    },
    {
        "name": "Women Cell",
        "members": [
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Coordinator" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Member" },
            { "image": "yogendra_nath_mishra.jpg", "name": "Mr. Yogendra Nath Mishra", "designation": "Member" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Member" },
            { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "designation": "Member" }
        ]
    },
    {
        "name": "Construction Committee",
        "members": [
            { "image": "sachin.jpg", "name": "Dr. Sachin", "designation": "Coordinator" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Member" },
            { "image": "jitendra_kumar_chaurasiya.jpg", "name": "Mr. Jitendra Kumar Chaurasiya", "designation": "Member" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Member" },
            { "image": "sumit_kumar_gupta.jpg", "name": "Dr. Sumit Kumar Gupta", "designation": "Member" }
        ]
    },
    {
        "name": "Mahavidyalaya Magazine Committee",
        "members": [
            { "image": "jai_prakash.jpg", "name": "Dr. Jai Prakash", "designation": "Coordinator" },
            { "image": "yogendra_nath_mishra.jpg", "name": "Mr. Yogendra Nath Mishra", "designation": "Member" },
            { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "designation": "Member" }
        ]
    },
    {
        "name": "Students Grievance Committee",
        "members": [
            { "image": "jai_prakash.jpg", "name": "Prof.(Dr.) Jai Prakash", "designation": "Coordinator" },
            { "image": "santosh_kumar.jpg", "name": "Mr. Santosh Kumar", "designation": "Member" },
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Member" },
            { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "designation": "Member" }
        ]
    },
    {
        "name": "Public Information Committee",
        "members": [
            { "image": "santosh_kumar.jpg", "name": "Mr. Santosh Kumar", "designation": "Coordinator" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Member" }
        ]
    },
    {
        "name": "Career & Counselling Committee",
        "members": [
            { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "designation": "Coordinator" },
            { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kannojia", "designation": "Member" },
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Member" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Member" },
            { "image": "jitendra_kumar_chaurasiya.jpg", "name": "Mr. Jitendra Kumar Chaurasiya", "designation": "Member" },
            { "image": "sumit_kumar_gupta.jpg", "name": "Dr. Sumit Kumar Gupta", "designation": "Member" }
        ]
    },
    {
        "name": "Outreach Lecture Committee",
        "members": [
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Coordinator" },
            { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kannojia", "designation": "Member" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Member" },
            { "image": "santosh_kumar.jpg", "name": "Mr. Santosh Kumar", "designation": "Member" },
            { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "designation": "Member" },
            { "image": "jitendra_kumar_chaurasiya.jpg", "name": "Mr. Jitendra Kumar Chaurasiya", "designation": "Member" }
        ]
    },
    {
        "name": "Environment & Hygiene Committee",
        "members": [
            { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "designation": "Coordinator" },
            { "image": "sachin.jpg", "name": "Dr. Sachin", "designation": "Member" },
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Member" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Member" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Member" }
        ]
    },
    {
        "name": "Equal Opportunity Cell",
        "members": [
            { "image": "sachin.jpg", "name": "Dr. Sachin", "designation": "Coordinator" },
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthan", "designation": "Member" },
            { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "designation": "Member" },
            { "image": "sumit_kumar_gupta.jpg", "name": "Dr. Sumit Kumar Gupta", "designation": "Member" }
        ]
    },
    {
        "name": "Academic Visit Committee",
        "members": [
            { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kannojia", "designation": "Coordinator" },
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Member" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Member" },
            { "image": "santosh_kumar.jpg", "name": "Mr. Santosh Kumar", "designation": "Member" }
        ]
    },
    {
        "name": "Information Communication Committee",
        "members": [
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Coordinator" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Member" },
            { "image": "yogendra_nath_mishra.jpg", "name": "Mr. Yogendra Nath Mishra", "designation": "Member" }
        ]
    },
    {
        "name": "Guardians Committee",
        "members": [
            { "image": "jai_prakash.jpg", "name": "Prof. (Dr.) Jai Prakash", "designation": "Coordinator" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Member" },
            { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "designation": "Member" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Member" }
        ]
    },
    {
        "name": "SC/ST Cell",
        "members": [
            { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kannojia", "designation": "Coordinator" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Member" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Member" }
        ]
    },
    {
        "name": "Minority Welfare Committee",
        "members": [
            { "image": "sumit_kumar_gupta.jpg", "name": "Dr. Sumit Kumar Gupta", "designation": "Coordinator" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Member" }
        ]
    },
    {
        "name": "Scholarship and Fees Reimbursement Committee",
        "members": [
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Coordinator/Nodal Officer" },
            { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "designation": "Coordinator, OBC Category" },
            { "image": "kishor_nand.jpg", "name": "Mr. Kishor Nand", "designation": "Member" },
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Coordinator, General and Minority Category" },
            { "image": "sachin.jpg", "name": "Dr. Sachin", "designation": "Member, General and Minority Category" },
            { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kannojia", "designation": "Coordinator, SC/ST Category" },
            { "image": "jai_prakash.jpg", "name": "Prof.(Dr.) Jai Prakash", "designation": "Member, SC/ST Category" }
        ]
    },
    {
        "name": "Alumni Committee",
        "members": [
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Coordinator" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Member" },
            { "image": "yogendra_nath_mishra.jpg", "name": "Mr. Yogendra Nath Mishra", "designation": "Member" }
        ]
    },
    {
        "name": "Anti-Ragging Committee",
        "members": [
            { "image": "santosh_kumar.jpg", "name": "Mr. Santosh Kumar", "designation": "Coordinator" },
            { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kannojia", "designation": "Member" },
            { "image": "vikash_kumar_sonkar.jpg", "name": "Mr. Vikash Kumar Sonkar", "designation": "Member" },
            { "image": "yogendra_nath_mishra.jpg", "name": "Mr. Yogendra Nath Mishra", "designation": "Member" }
        ]
    },
    {
        "name": "IQAC Committee",
        "members": [
            { "image": "jai_prakash.jpg", "name": "Prof.(Dr.) Jai Prakash", "designation": "Coordinator" },
            { "image": "sachin.jpg", "name": "Dr. Sachin", "designation": "Member" },
            { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "designation": "Member" },
            { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kannojia", "designation": "Member" },
            { "image": "santosh_kumar.jpg", "name": "Mr. Santosh Kumar", "designation": "Member" }
        ]
    }
];


export const governanceData = [
    {
      "title": "Board of Governance",
      "members": [
        { "image": "soumitra_chandra.jpg", "name": "Prof. (Dr.) Soumitra Chandra", "role": "Principal/President" },
        { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kumar Kannojia", "role": "Coordinator/Secretary" },
        { "image": "sachin.jpg", "name": "Dr. Sachin", "role": "Member" },
        { "image": "jai_prakash.jpg", "name": "Prof. (Dr.) Jai Prakash", "role": "Member" },
        { "image": "shailja_asthana.jpg", "name": "Dr. Shailja Asthana", "role": "Member" },
        { "image": "jitendra_kumar_chaurasiya.jpg", "name": "Shri Jitendra Kumar Chaurasiya", "role": "Member" },
        { "image": "sumit_kumar_gupta.jpg", "name": "Dr. Sumit Kumar Gupta", "role": "Member" },
        { "image": "brijesh_kumar_pandey.jpg", "name": "Shri Brijesh Kumar Pandey", "role": "Member" }
      ]
    },
    {
      "title": "Project Monitoring Unit",
      "members": [
        { "image": "soumitra_chandra.jpg", "name": "Prof. (Dr.) Soumitra Chandra", "role": "Principal" },
        { "image": "manish_kannojia.jpg", "name": "Dr. Manish Kumar Kannojia", "role": "Coordinator" },
        { "image": "sachin.jpg", "name": "Dr. Sachin", "role": "Member" },
        { "image": "sanjai_kumar_gupta.jpg", "name": "Dr. Sanjai Kumar Gupta", "role": "Member" },
        { "image": "manoj_kharwar.jpg", "name": "Shri Manoj Kumar Kharwar", "role": "Member" },
        { "image": "santosh_kumar.jpg", "name": "Shri Santosh Kumar", "role": "Member" },
        { "image": "vikas_kumar_sonkar.jpg", "name": "Shri Vikas Kumar Sonkar", "role": "Member" },
        { "image": "kishor_nand.jpg", "name": "Shri Kishorenand", "role": "Member" },
        { "image": "yogendra_nath_mishra.jpg", "name": "Shri Yogendra Nath Mishra", "role": "Member" },
        { "image": "jitendra_kumar_chaurasiya.jpg", "name": "Shri Jitendra Kumar Chaurasiya", "role": "Member" },
        { "image": "sumit_kumar_gupta.jpg", "name": "Dr. Sumit Kumar Gupta", "role": "Member" },
        { "image": "brijesh_kumar_pandey.jpg", "name": "Shri Brijesh Kumar Pandey", "role": "Member" }
      ]
    }
  ]