import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import { governanceData } from '../components/committe'
import UserCard from '../components/UserCard'

function RUSA() {
  return (
    <div>
      <PageHeader title="PM Usha" />
      <PageContent elem={<div  style={{paddingInline:'20px'}}>
      <section className="mb-5 mt-5">
    <h3 className="text-xl font-semibold mb-2">Broad Objectives of the College</h3>
    <br/>
    
    <ul className="list-disc pl-5">
      <li>To provide accessible and affordable higher education opportunities.</li>
      <li>To empower students with knowledge and critical thinking skills to make informed decisions.</li>
      <li>To design and implement curriculum aligning with the needs and aspirations of the community.</li>
      <li>To actively engage with the local community, building stronger partnerships & collaborations.</li>
      <li>To foster holistic development by nurturing students.</li>
      <li>To offer skill development programs for job readiness.</li>
      <li>To encourage research and innovation among students.</li>
    </ul>
    <br/>
    <br/>
  </section>

  <div className="container mx-auto my-5">
                <h2 className="text-center bg-primary text-white text-2xl font-bold mb-5" style={{padding:'10px'}}>Board of Governance</h2>
               <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-between'}}>
                 
                {governanceData.find((c)=>c.title=="Board of Governance").members.map((members, index) => (
                  
                      <UserCard image={`https://campus.acetians.in/custom/images/upload/${members.image}`} name={members.name} role={members.designation} />
                    ))}
                </div>
              </div>
  <br/>
  <hr/>
  <br/>
              <div className="container mx-auto my-5">
                <h2 className="text-center bg-primary text-white text-2xl font-bold mb-5" style={{padding:'10px'}}>Project Monitoring Unit (PMU) Details</h2>
               <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-between'}}>
                 
                {governanceData.find((c)=>c.title=="Project Monitoring Unit").members.map((members, index) => (
                  
                      <UserCard image={`https://campus.acetians.in/custom/images/upload/${members.image}`} name={members.name} role={members.designation} />
                    ))}
                </div>
              </div>
  
  
  </div>} />
      
    </div>
  )
}

export default RUSA
