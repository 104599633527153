import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import Blog from '../components/Blog'

function WellEquippedLabs() {
  return (
    <div>
      <PageHeader title="Well-Equipped Labs" />
      <PageContent elem={<Blog image='' content='<p>The college provides well-equipped laboratories like Physics, Chemistry, Botany, Zoology Labs for practical learning, ensuring students get hands-on experience in their respective fields.</p>' />}/>
    </div>
  )
}

export default WellEquippedLabs
