import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import CommitteesTable from '../components/Committee'

function Commitees() {
  return (
    <div>
      <PageHeader title="Commitees" />
      <PageContent elem={<CommitteesTable/>}/>
    </div>
  )
}

export default Commitees
