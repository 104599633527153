import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import Blog from '../components/Blog'

function Library() {
  return (
    <div>
      <PageHeader title="Library" />
      <PageContent elem={<Blog image='' content='<p>The college library provides a vast collection of books, journals, and digital resources to support student learning.</p>' />}/>
    </div>
  )
}

export default Library
