import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import Blog from '../components/Blog';
import { useSelector } from 'react-redux';


function PrincipalMessage() {

  const store = useSelector((state) => state.auth.store);
  return (
    <div>
      <PageHeader title="Pricipal Message" />
      <PageContent elem={<><Blog image={`https://campus.acetians.in/custom/images/${store.principal_image}`} content={store.principal_message} />
      <br/>
      <div className=''>
    <h2>
    Message to Our Students and Community  
          </h2>
  
    <p style={{textAlign:'justify'}}>
Education is the cornerstone of human progress. It not only preserves our rich cultural heritage but also equips us to stride confidently alongside the world's most developed nations. In the 21st century, the fusion of science and technology will unlock unprecedented possibilities, expanding human potential beyond the boundaries of this world.      
    </p>
    <p  style={{textAlign:'justify'}}>
    At our institution, we are committed to providing an education that is not only strategic and of the highest quality but also one that empowers our students to become economically independent and socially responsible citizens of India. Our goal is to nurture individuals who are equipped to meet the challenges of tomorrow while embodying the values that have defined our great nation for thousands of years.
    </p>
    <p style={{textAlign:'justify'}}>
    With state-of-the-art infrastructure and a team of innovative educators, we are dedicated to fostering an environment where knowledge, discipline, and character are paramount. We are confident that our students will play a pivotal role in the ongoing journey of nation-building, and that they will see India emerge as a leading force on the global stage.
    </p>
  <p style={{textAlign:'justify'}}>
Together, step by step, we will continue to build a brighter future for our country. My heartfelt best wishes to all!    
  </p>



    </div>
      </>}/>
      
    </div>
  )
}

export default PrincipalMessage
