import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import Blog from '../components/Blog'

function SportsGround() {
  return (
    <div>
      <PageHeader title="Sports Ground" />
      <PageContent elem={<Blog image='' content='<p>Our sports ground is well-maintained and provides space for students to engage in various sports and physical activities.</p>' />}/>
    </div>
  )
}

export default SportsGround
