import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import Blog from '../components/Blog'

function ElearningPark() {
  return (
    <div>
      <PageHeader title="E Learning Park" />
      <PageContent elem={<Blog image='' content='<p>We have  eLearing Parks make education easy to grasp for all.</p>' />}/>
    </div>
  )
}

export default ElearningPark
