import React from "react";
import PropTypes from "prop-types";

const UserCard = ({ image, name, role }) => {
  // Styles
  const styles = {
    card: {
      backgroundColor: "#ffffff",
      borderRadius: "12px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
      padding: "24px 16px",
      textAlign: "center",
      width: "200px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      transition: "transform 0.2s ease, box-shadow 0.2s ease",
      "&:hover": {
        transform: "translateY(-4px)",
        boxShadow: "0 6px 16px rgba(0, 0, 0, 0.12)"
      }
    },
    image: {
      width: "140px",
      height: "140px",
      borderRadius: "50%",
      objectFit: "cover",
      border: "3px solid #f0f0f0",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)"
    },
    name: {
      margin: "16px 0 4px",
      fontSize: "16px",
      fontWeight: "600",
      color: "#2d3748",
      lineHeight: "1.4"
    },
    role: {
      fontSize: "14px",
      color: "#718096",
      margin: 0,
      lineHeight: "1.5"
    }
  };

  return (
    <div style={styles.card}>
      <img 
        src={image} 
        alt={`Profile of ${name}`} 
        style={styles.image} 
      />
      <h3 style={styles.name}>{name}</h3>
      <p style={styles.role}>{role}</p>
    </div>
  );
};

// PropTypes validation
UserCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired
};

export default UserCard;