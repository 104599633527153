import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import Blog from '../components/Blog'

function SmartClass() {
  return (
    <div>
      <PageHeader title="Smart Classrooms" />
      <PageContent elem={<Blog image='' content='<p>Smart Classrooms with digital boards and interactive learning tools enhance the teaching and learning experience.</p>' />}/>
    </div>
  )
}

export default SmartClass
