import React from 'react'
import { Link } from 'react-router-dom'
import HeroCarousel from '../components/HeroCarousel'
import VIP from '../components/VIP'
import Chancellors from '../components/vips.json'
import AboutUsSection from '../components/AboutSection'
import LatestNewsMarquee from './LatestNewsMarquee'
import EventsSection from './EventsSection'
import QuickButton from '../components/QuickButton'
import Facilities from '../components/Facilities'
import CTA from '../components/CTA'
import GallerySlide from '../components/GallerySlide'
import { useSelector } from 'react-redux'

function Home() {
  const store = useSelector((state) => state.auth.store);
  const quicklinks=[
    {
      'name':'Exams',
      'image':'https://campus.acetians.in/custom/exam.png',
      
    },
    {
      'name':'Admissions',
      'image':'https://campus.acetians.in/custom/letter.png',
      
    },
    {
      'name':'Results',
      'image':'https://campus.acetians.in/custom/result.png',
      
    },
    {
      'name':'Students',
      'image':'https://campus.acetians.in/custom/student.png',
      
    },
    {
      'name':'University',
      'image':'https://campus.acetians.in/custom/school.png',
      
    },
    {
      'name':'Academics',
      'image':'https://campus.acetians.in/custom/education.png',
      
    }
  ] 
  const events = [
    {
      title: "पाँच दिवसीय रोवर्स-रेंजर्स प्रशिक्षण एवं जांच शिविर काआयोजन",
      link: "#",
      image: "#",
      date: "March 11, 2024",
    },
    {
      title: "राष्ट्रीय सेवा योजना के अंतर्गत सप्तदिवसीय विशेष शिविर संबंधित सूचना",
      link: "#",
      image: "#",
      date: "February 19, 2024",
    },
  ];

  const nextEvents = [
    {
      title: "पाँच दिवसीय रोवर्स-रेंजर्स प्रशिक्षण एवं जांच शिविर काआयोजन",
      link: "#",
      date: "March 11, 2024",
    },
    {
      title: "राष्ट्रीय सेवा योजना के अंतर्गत सप्तदिवसीय विशेष शिविर संबंधित सूचना",
      link: "#",
      date: "February 19, 2024",
    },
    {
      title: "Job Fair from Regional Employment Office Gorakhpur",
      link: "#",
      date: "April 22, 2023",
    },
  ];
  return (
    <div>
      <div className='d-flex w-100' style={{maxWidth:'100vw'}}>
         <div className='col-md-9 p-0' style={{padding:0}}>
         <HeroCarousel/>
         </div>
         <div className='col-md-3'>
          <ul style={{padding:'0px',margin:'0px'}}>
            {Chancellors.map((chancellor,index)=>(<VIP key={index} chancellor={chancellor}/>))}
          </ul>
         </div>
      </div>
    <LatestNewsMarquee newsItems={[]}/>

    <ul id="quicklinks-ul">
      {quicklinks.map((link,index)=>(<QuickButton key={index} link={link}/>))}
    </ul>
    
    <AboutUsSection
      title="Welcome to"
      subtitle={store.store_title.split("=")[0]}
      description={store.about_page}
      eContentLink="/e-pathshalas/"
      feedbackLink="#"
      imageUrl={store.about_image}   
    /> 
    
    <EventsSection events={events} nextEvents={nextEvents}/>
    <AboutUsSection
      title="PRINCIPAL'S"
      subtitle="MESSAGE"
      description={`${store.principal_message}`}
      eContentLink=""
      feedbackLink=""
      imageUrl={`https://campus.acetians.in/custom/images/${store.principal_image}`}
    />
    
    <Facilities/>
    <CTA/>
    <GallerySlide/>
    </div>
  )
}

export default Home
