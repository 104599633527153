import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import Blog from '../components/Blog'

function ReadingRoom() {
  return (
    <div>
      <PageHeader title="Reading Room" />
      <PageContent elem={<Blog image='' content='<p>The college offers a dedicated reading room for students to focus on their studies in a quiet environment.</p>' />}/>
    </div>
  )
}

export default ReadingRoom
