import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent' 
import UserCard from '../components/UserCard'
import { committees } from '../components/committe'

function IQAC({com}) {
  return (
    <div>
      <PageHeader title={com} />
      <PageContent elem = {
        <div className="container mx-auto my-5">
              <h2 className="text-center text-primary text-2xl font-bold mb-5">{com}</h2>
             <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-between'}}>
               
              {committees.find((c)=>c.name==com).members.map((members, index) => (
                
                    <UserCard image={`https://campus.acetians.in/custom/images/upload/${members.image}`} name={members.name} role={members.designation} />
                  ))}
              </div>
            </div>
      } />
    </div>
  )
}

export default IQAC
