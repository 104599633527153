import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import Blog from '../components/Blog'

function ComputerLab() {
  return (
    <div>
      <PageHeader title="Computer Lab" />
      <PageContent elem={<Blog image='' content='<p>Our Computer Lab is well-equipped with modern computing facilities to provide students with the best hands-on learning experience.</p>' />}/>
    </div>
  )
}

export default ComputerLab
