import React from 'react';
import PageHeader from '../components/PageHeader';
import PageContent from '../components/PageContent';
import TableComponent from '../components/TableComponent';

function Syllabus() {
    const thead = ['S.No', 'Department', 'Syllabus Link'];
    const tbody = [
        [1, "B.A. - English", "https://ddugu.ac.in/newweb/pdf/2024092718030355337-DEPCBCS-BA-Honours.pdf"],
        [2, "B.A. - Hindi", "https://ddugu.ac.in/newweb/pdf/C938653@100489c4b597i.pdf"],
        [3, "B.A. - Political Science", "https://ddugu.ac.in/newweb/pdf/2024092712343472333-DEPCBCS-New-B.A.-Sylbuss-Political-Science-2024-pdf.pdf"],
        [4, "B.A. - Education", "https://ddugu.ac.in/newweb/pdf/2024093014384093358-DEPCBCS-B-A-FOUR-YEAR-CBCS--Syllabus-Format-DDU-GKPcompressed.pdf"],
        [5, "B.A. - Physical Education", "https://ddugu.ac.in/newweb/pdf/2024092807232669360-DEPCBCS-Syllabus-31-Physical-Education-2024.pdf"],
        [6, "B.A. - History", "https://ddugu.ac.in/newweb/pdf/2024100814573145331-DEPCBCS-NEW-CODE-BA-4-YEAR-2024-1.pdf"],
        [7, "B.A. - Sociology", "https://ddugu.ac.in/newweb/pdf/2024092912333757335-DEPCBCS-SOC-UG-4-Year-2024.pdf"],
        [8, "B.Sc. - Physics", "https://ddugu.ac.in/newweb/pdf/2024073112302791356-DEPCBCS-Syllabus4-Years-UGPhysics24-25.pdf"],
        [9, "B.Sc. - Chemistry", "https://ddugu.ac.in/newweb/pdf/2024082112511748350-DEPCBCS-BSc-ChemistrySyllabus-2024.pdf"],
        [10, "B.Sc. - Botany", "https://ddugu.ac.in/newweb/pdf/2024071016181321349-DEPCBCS-Botany-31.pdf"],
        [11, "B.Sc. - Zoology", "https://ddugu.ac.in/newweb/pdf/2024083118290217357-DEPCBCS-New-syllabus-four-year-BSc-ZOOLOGY.pdf"],
        [12, "B.Sc. - Mathematics", "https://ddugu.ac.in/newweb/pdf/2024090311480067355-DEPCBCS-0001NE1.PDF"],
        [13, "B.Com.", "https://ddugu.ac.in/newweb/pdf/2024111218072684340-DEPCBCS-UG-4-Years-Coding-F.pdf"],
        [14, "M.Com.", "https://ddugu.ac.in/newweb/pdf/2024082405135642340-DEPCBCS-M.COM-PROGRAMME11zon.pdf"],
    ];

    return (
        <div>
            <PageHeader title="Syllabus" />
            <PageContent elem={<TableComponent thead={thead} tbody={tbody} />} />
        </div>
    );
}

export default Syllabus;
